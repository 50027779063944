import React, { useCallback, useEffect, useState } from 'react';
import Footer from '../../elements/footer/Footer';
import Navbar from '../../elements/navbar/Navbar';

import {
  InnerContainer,
  MainContainer,
  Button,
  VehicleDetails,
  Details,
  ErrorMsg,
} from './styles';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  getSessionBookingAction,
  resetError,
} from '../../features/booking/bookingSlice';
import usePrevious from '../../hooks/usePrevious';
import {
  getVehicleById,
  resetSelectedVehicle,
} from '../../features/vehicle/vehicleSlice';

import Processing from '../../components/Processing';

import briefcaseIcon from '../vehicles/images/briefcase-icon.svg';
import peopleIcon from '../vehicles/images/people-icon.svg';
import cancelIcon from '../vehicles/images/cancel-icon.svg';
import nameplateIcon from '../vehicles/images/nameplate-icon.svg';
import clockIcon from '../vehicles/images/clock-icon.svg';
import { newPayment } from '../../features/payment/paymentSlice';
import environment from '../../env';

const CardForm = ({ clientSecret, email }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    setLoading(true);

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setLoading(false);
      // console.log('PAYMENT_STATUS', paymentIntent.status);
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [clientSecret, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const { error, ...other } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          environment !== 'production'
            ? 'http://localhost:3000/payment-success'
            : 'https://www.booking.rado-chauffeurs.com/payment-success',
        receipt_email: email,
      },
    });

    //  console.log({ error, other });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setLoading(false);
  };

  return (
    <div className="m-auto">
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />

        {elements && stripe && !loading ? (
          <Button disabled={loading || !stripe || !elements} id="submit">
            <span id="button-text">
              {loading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                'Pay now'
              )}
            </span>
          </Button>
        ) : (
          <Processing />
        )}
        {/* Show any error or success messages */}
        {message && <ErrorMsg id="payment-message">{message}</ErrorMsg>}
      </form>
    </div>
  );
};

const Trip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { clientSecret } = useSelector((state) => state.payment);

  // console.log({ clientSecret });

  const { booking, isLoading, isError, message, bookingUpdated } = useSelector(
    (state) => state.booking
  );

  const { vehicles, selectedVehicle } = useSelector((state) => state.vehicle);

  // console.log({ selectedVehicle });

  useEffect(() => {
    if (!clientSecret) {
      dispatch(newPayment());
    }
  }, [clientSecret, dispatch]);

  const prevIsLoading = usePrevious(isLoading);

  // console.log({ booking });

  const getSessionBooking = useCallback(() => {
    dispatch(getSessionBookingAction());
  }, [dispatch]);

  useEffect(() => {
    getSessionBooking();
  }, [getSessionBooking]);

  useEffect(() => {
    if (booking?.vehicleId && !selectedVehicle) {
      dispatch(getVehicleById(booking.vehicleId));
    }
  }, [booking?.vehicleId, dispatch, selectedVehicle]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedVehicle());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      (isError && message === 'no session or session expired') ||
      (prevIsLoading && isError && !booking?._id)
    ) {
      navigate('/');
    }
  }, [message, navigate, dispatch, isError, prevIsLoading, booking?._id]);

  const PUBLIC_KEY =
    environment !== 'production'
      ? 'pk_test_51LZHVYItf1lkio9swIuJ7T7xCD9rgchcMde39vqdoF2yPz0MtLh1f8eLG7hqxS9aWLUqB1vUVv6qCIMIiZL6v7A300MkRDh8lS'
      : 'pk_live_51HE1WtEkqFee9MyxLF9NFeNBBCEUyId7ki5dEOJq5COmrgOjEfkWljyY4GX2rHGfcu8XWQBQobHKUxR4yEXlHJwX00LWfXWcWQ';

  const stripeTestPromise = loadStripe(PUBLIC_KEY);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Navbar />
      <MainContainer>
        <InnerContainer>
          <h2>Booking summary</h2>
          {selectedVehicle?.id && (
            <VehicleDetails>
              <img
                className="car-image"
                src={`/upload/${selectedVehicle.image}`}
                alt="selected vehicle"
              />
              <Details>
                <h3>{selectedVehicle.name}</h3>
                <p>
                  <span className="capacity">
                    <img src={peopleIcon} alt="people" />
                    {selectedVehicle.passengerCapacity}
                  </span>
                  <span className="capacity">
                    <img src={briefcaseIcon} alt="briefcase" />
                    {selectedVehicle.luggageCapacity}
                  </span>
                </p>
                <p>{selectedVehicle.description}</p>
                <p>
                  <img className="benefit-icon" src={cancelIcon} alt="cancel" />
                  Free cancelation
                </p>
                <p>
                  <img className="benefit-icon" src={clockIcon} alt="clock" />1
                  hour of free waiting included
                </p>
                <p>
                  <img
                    className="benefit-icon"
                    src={nameplateIcon}
                    alt="nameplate"
                  />
                  Metting with a nameplate
                </p>
              </Details>
            </VehicleDetails>
          )}
          {booking?._id && (
            <>
              <h2>Trip summary</h2>
              <div>
                <h3>Pickup date and time</h3>
                <p>
                  {new Date(booking.dateTime).toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </p>
              </div>
              {booking.returnDateTime && (
                <div>
                  <h3>Return journey pickup date and time</h3>
                  <p>
                    {new Date(booking.returnDateTime).toLocaleString('en-GB', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                </div>
              )}

              <div>
                <h3>From:</h3>
                <p>{booking.fromAddress}</p>
              </div>

              <div>
                <h3>To:</h3>
                <p>{booking.toAddress}</p>
              </div>
              <h1>{`TOTAL: £${booking.price}`}</h1>
            </>
          )}
          {clientSecret && booking?.passengerEmail && (
            <Elements options={options} stripe={stripeTestPromise}>
              <CardForm
                clientSecret={clientSecret}
                email={booking.passengerEmail}
              />
            </Elements>
          )}
          <p>
            By submitting a booking request, you are confirming that you have
            read and agree to Rado Chauffeurs’s Terms of Use and Privacy Policy.
          </p>
        </InnerContainer>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Trip;
