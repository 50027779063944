const pricesData = require('./prices.json');

function matchPrice(type, distance, pickupDateTime) {
  let price = 0;

  pricesData.prices?.forEach((e) => {
    if (distance >= e.distance[0] && distance <= e.distance[1]) {
      price = e[type];
    }
  });

  // between hours 20:00 and 22:00, add 12% to the price
  const time = new Date(pickupDateTime).getHours();

  if (time >= 20 && time < 22) {
    price *= 1.12;
  }

  // between hours 22:00 and 06:00, add 22% to the price
  if (time >= 22 || time < 6) {
    price *= 1.22;
  }

  return price * distance;
}

export default matchPrice;
