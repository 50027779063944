import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import vehicleService from './vehicleService';

const initialState = {
  vehicles: null,
  selectedVehicle: null,
  isError: false,
  isLoading: false,
  message: '',
};

const parseMessage = (err) =>
  (err.response && err.response.data && err.response.data.message) ||
  err.message ||
  err.toString();

export const getAllVehicles = createAsyncThunk(
  'vehicle/getAllVehicles',
  async (body, thunkAPI) => {
    try {
      return await vehicleService.getAllVehicles(body);
    } catch (error) {
      return thunkAPI.rejectWithValue(parseMessage(error));
    }
  }
);

export const getVehicleById = createAsyncThunk(
  'vehicle/getVehicleById',
  async (id, thunkAPI) => {
    try {
      return await vehicleService.getVehicleById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(parseMessage(error));
    }
  }
);

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    resetError: (state) => {
      state.isError = null;
      state.message = '';
    },
    resetSelectedVehicle: (state) => {
      state.selectedVehicle = null;
    },

    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicles.pending, (state) => {
        state.isLoading = true;
        state.message = '';
        state.isError = false;
      })
      .addCase(getAllVehicles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vehicles = action.payload.vehicles;
      })
      .addCase(getAllVehicles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getVehicleById.pending, (state) => {
        state.isLoading = true;
        state.message = '';
        state.isError = false;
      })
      .addCase(getVehicleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedVehicle = action.payload.vehicle;
      })
      .addCase(getVehicleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, resetError, resetSelectedVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;
